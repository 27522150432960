<template>
  <div>
    <div class="form-group">
      <label for>Presupuesto</label>
      <app-input-money
        ref="pInputMoney"
        :quantity.sync="max"
        :moneyCode.sync="money_code"
      ></app-input-money>
    </div>
    <app-button-submit @click="save()"></app-button-submit>
  </div>
</template>
<script>
import { AdminService } from "../AdminService";
export default {
  components: {
    //
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    reason_id: 1,
    max: 0,
    money_code: 1
  }),

  computed: {
    //
  },

  watch: {
    //
  },

  mounted() {
    //
  },

  methods: {
    setReasonId(reason_id) {
      this.$refs.pInputMoney.setDefault();
      this.reason_id = reason_id;
    },
    save() {
      AdminService.saveReasonBudget(this.$data).then(() => {
        this.$emit("submitted");
      });
    }
  }
};
</script>

<style></style>
