<template>
  <select class="custom-select" v-model="valueChild">
    <option v-for="(item, index) in items" :key="index" :value="index"
      >{{ item.name }}
    </option>
  </select>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      // items: {},
      // selected: 0
    };
  },
  computed: {
    valueChild: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    ...mapState({
      items: s => s.config.admin.reasons.types
    })

    // items() {
    //   return this.$store.state.config.admin ? this.$store.state.config.admin.reasons.types : []
    // }
  },
  props: {
    value: {
      required: true
    }
  },
  methods: {
    // getList () {
    //   http.get('api/admin/config')
    //     .then(res => {
    //       this.items = res.data.reasons.types
    //       // commit('setAdminConfig', res.data)
    //       // localStorage.setItem('admin', JSON.stringify(res.data))
    //     })
    // }
  },
  created() {
    // this.getList()
  }
};
</script>

<style>
</style>
