<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <h4>RAZONES DE EGRESO</h4>

        <app-table-registers
          ref="tr"
          :getList="getList"
          @btnNew="
            $refs.diaFormReason.show();
            $refs.reasonsForm.reset();
          "
        >
          <template slot="table">
            <thead>
              <tr>
                <th>ID</th>
                <th>FECHA</th>
                <th>NOMBRE</th>
                <th>AREA</th>
                <th class="text-right">PRESUPUESTO</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="l in list.data" :key="l.id">
                <td>{{ l.id }}</td>
                <td>{{ l.created_at }}</td>
                <td>{{ l.name }}</td>
                <td>{{ l.area_name }}</td>
                <td class="text-right">
                  <app-span-money
                    v-if="l.budget && l.free != 1"
                    :quantity="l.budget.max"
                    :moneyCode="l.budget.money_code"
                  ></app-span-money>
                </td>
                <td class="text-right">
                  <div class="btn-group">
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.elFoalForm.setReasonId(l.id);
                        $refs.dAddGoalReason.show();
                      "
                    >
                      <i class="fa fa-flag"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.reasonsForm.loadReg(l);
                        $refs.diaFormReason.show();
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm"
                      @click="
                        $refs.elDelReason.val = l.id;
                        $refs.elDelReason.show();
                      "
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </app-table-registers>
      </div>
    </div>

    <app-modal-basic ref="diaFormReason" idModal="diaFormReason">
      <h4>FORMULARIO DE RAZON DE EGRESO</h4>
      <reasonsForm
        v-if="$refs.diaFormReason"
        ref="reasonsForm"
        @submitted="
          $refs.diaFormReason.hide();
          getList();
        "
      ></reasonsForm>
    </app-modal-basic>

    <app-modal-yn ref="elDelReason" @yes="deleteItem($refs.elDelReason.val)">
      <p>
        Seguro(a) de eliminar el registro
        <b>{{ $refs.elDelReason && $refs.elDelReason.val }}</b
        >?
      </p>
    </app-modal-yn>

    <app-modal-basic ref="dAddGoalReason">
      <h4>MODIFICAR PRESUPUESTO</h4>
      <goal-form
        ref="elFoalForm"
        @submitted="
          $refs.tr.gl();
          $refs.dAddGoalReason.hide();
        "
      ></goal-form>
    </app-modal-basic>
  </div>
</template>

<script>
import Form from "./Form";
import { AdminService } from "../AdminService";
import goalForm from "./GoalForm";

export default {
  components: {
    reasonsForm: Form,
    goalForm
  },
  data() {
    return {
      list: {}
    };
  },
  created() {
    this.getList();
  },
  methods: {
    deleteItem(itemID) {
      AdminService.deleteReason(itemID).then(() => {
        this.list.data.map((x, index) => {
          if (x.id == itemID) {
            this.list.data.splice(index, 1);
          }
          return x;
        });
        this.$refs.elDelReason.hide();
      });
    },
    getList(params) {
      return new Promise(resolve => {
        AdminService.getReasons(params).then(result => {
          this.list = result;
          resolve(result);
        });
      });
    }
  }
};
</script>

<style></style>
